<template>
  <a
    v-if="this.shouldDisplay()"
    :href="this.imageLink()"
    class="LeaderboardAd"
  >
    <img
      :src="this.imageSrc()"
      class="column is-header-ad is-hidden-mobile has-text-centered px-4"
      alt="advertisemnt"
    >
  </a>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('advertisements', {
      ads: (state) => state.leaderboard_ads,
    }),
  },
  methods: {
    shouldDisplay() {
      return !!this.ads?.length;
    },
    imageSrc() {
      return Array.from(this.ads)[0].src;
    },
    imageLink() {
      return Array.from(this.ads)[0].link;
    },
  },
};
</script>
